<template>
  <div>
    <a-tabs :active-key="activeKey" @change="changeTab">
      <a-tab-pane
        v-for="item in tabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: 'ServiceCycleManageShowTab',
  data() {
    return {
      activeKey: '',
      tabs: [
        { routeName: 'service_cycle_unexpired', tabName: '服务周期即将到期' },
        { routeName: 'service_cycle_expired', tabName: '服务周期已到期' },
        { routeName: 'current_month_expect_cancel', tabName: '本月预计销卡列表' },
        { routeName: 'service_cycle_renewed', tabName: '续期记录' },
        { routeName: 'service_cycle_canceled', tabName: '销卡记录' }
      ]
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.activeKey = this.$route.name
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.activeKey = this.$route.name
  },
  methods: {
    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    }
  }
}
</script>
